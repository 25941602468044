import React, { useState, useEffect, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './Dropdownnew.css';
import './Scores.css';
import './Graph.css';
import './Health.css';
import Graph from './features/Graph';
import Graphnew from './features/Graphnew';
import LandingGraph from './features/LandingGraph';
import { formatDateTime } from './features/utils';
import GraphOptionsDropdown from './features/GraphOptionsDropdown';
import CustomDropdown from './CustomDropdown';


function Landing({ sidebarOpen, onSelect, onLogout }) {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dataSources, setDataSources] = useState([]);
    const [servers, setServers] = useState([]);
    const [selectedDataSource, setSelectedDataSource] = useState('');
    const [selectedServer, setSelectedServer] = useState('');
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [serverCount, setServerCount] = useState(0);
    const [lastDuration, setLastDuration] = useState('6 hours');
    const [healthScore, setHealthScore] = useState(0);
    const [clusterScore, setClusterScore] = useState(0);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [refreshKey, setRefreshKey] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        function getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(';').shift();
        }
        
        const token = getCookie('token');
        if(!token){
            localStorage.clear();
onLogout();
        }
        const currentTime = new Date();
        const SixtyMinutesAgo = new Date(currentTime.getTime() - 6 * 60 * 60000);
        setEndTime(currentTime);
        setStartTime(SixtyMinutesAgo);
    }, []);

    useEffect(() => {
        fetchClusters();
    }, []);

    const fetchClusters = useCallback(async () => {
        function getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            }
            
            const token = getCookie('token');
            const username = getCookie('username');
            const email = decodeURIComponent(username);
            const url = `https://infia.enviseer.com/api/clusters?email=${username}`;
            console.log("Cluster", url);
        setLoading(true);
        setError(null); // Reset error state

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`, // Adding the Authorization header
                    'Accept': 'application/json'
                }
            });
            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.clear();
onLogout();
                } else {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            }
            const data = await response.json();
            setDataSources(data);
            if (data.length > 0) {
                const firstDataSource = data[0];
                setSelectedDataSource(firstDataSource);
                fetchServers(firstDataSource);
            }
        } catch (error) {
            console.log("Response Status:", error);
            setDataSources([]);
            if (dataSources.length === 0) {
                alert('Please contact your administrator. No data sources available.');
            }
            console.error("Failed to fetch clusters:", error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchServers = useCallback(async (dataSource) => {
        const url = `https://infia.enviseer.com/api/clusters/${dataSource}/hostnames`;
        console.log(url);
         function getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            }
            
            const token = getCookie('token');
        setLoading(true);
        setError(null); // Reset error state

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`, // Adding the Authorization header
                    'Accept': 'application/json'
                }
            });
            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.clear();
onLogout();
                } else {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            }
            const data = await response.json();
            setServers(data);
            setServerCount(data.length);
            if (data.length > 0) {
                setSelectedOptions(prevOptions => {
                    const newOptions = [...prevOptions];
                    newOptions[0] = { value: data[0], label: data[0] };
                    return newOptions;
                });
            }
        } catch (error) {
            console.error("Failed to fetch clusters:", error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }, []);

    const handleDataSourceChange = (event) => {
        const selected = event.target.value;
        setSelectedDataSource(selected);
        fetchServers(selected);
    };

    const handleStartTimeChange = (date) => {
        if (endTime) {
            const startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
            const endDate = new Date(endTime.getFullYear(), endTime.getMonth(), endTime.getDate());
            if (startDate > endDate) {
                setErrorMessage('Start date should be less than end date');
                return;
            }
            if (startDate.getTime() === endDate.getTime() && date >= endTime) {
                setErrorMessage('Start time should be less than end time');
                return;
            }
        }
        setErrorMessage('');
        setStartTime(date);
    };

    const handleEndTimeChange = (date) => {
        const currentTime = new Date();
        if (!startTime) {
            setErrorMessage('Please set a start time first');
            return;
        }
        const startDate = new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate());
        const endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        if (endDate < startDate) {
            setErrorMessage('End date should be greater than start date');
            return;
        }

        if (startDate.getTime() === endDate.getTime() && date <= startTime) {
            setErrorMessage('End time should be greater than start time');
            return;
        }
        if (date > currentTime) {
            setErrorMessage('End time cannot be in the future, using current time instead');
            date = currentTime;
        } else {
            setErrorMessage('');
        }
        setEndTime(date);
    };

    const filterPassedTime = (time) => {
        const currentTime = new Date();
        const selectedTime = new Date(time);
        return currentTime.getTime() >= selectedTime.getTime();
    };


    const handleLastDurationChange = (event) => {
        const selectedDuration = event.target.value;
        setLastDuration(selectedDuration);

        const currentTime = new Date();
        let newStartTime = null;
        let newEndTime = currentTime;

        switch (selectedDuration) {
            case '5 minutes':
                newStartTime = new Date(currentTime.getTime() - 5 * 60000);
                break;
            case '10 minutes':
                newStartTime = new Date(currentTime.getTime() - 10 * 60000);
                break;
            case '30 minutes':
                newStartTime = new Date(currentTime.getTime() - 30 * 60000);
                break;
            case '1 hour':
                newStartTime = new Date(currentTime.getTime() - 60 * 60000);
                break;
            case '2 hours':
                newStartTime = new Date(currentTime.getTime() - 2 * 60 * 60000);
                break;
            case '4 hours':
                newStartTime = new Date(currentTime.getTime() - 4 * 60 * 60000);
                break;
            case '6 hours':
                newStartTime = new Date(currentTime.getTime() - 6 * 60 * 60000);
                break;
            case '12 hours':
                newStartTime = new Date(currentTime.getTime() - 12 * 60 * 60000);
                break;
            case '1 day':
                newStartTime = new Date(currentTime.getTime() - 24 * 60 * 60000);
                break;
            case '3 days':
                newStartTime = new Date(currentTime.getTime() - 3 * 24 * 60 * 60000);
                break;
            case '1 week':
                newStartTime = new Date(currentTime.getTime() - 7 * 24 * 60 * 60000);
                break;
            case 'custom':
                newStartTime = null;
                newEndTime = null;
                break;
            default:
                newStartTime = startTime;
                newEndTime = endTime;
                break;
        }

        setStartTime(newStartTime);
        setEndTime(newEndTime);
    };

    const dismissErrorMessage = () => {
        setErrorMessage('');
    };
    
    const handleDropdownChange = (index, selectedOption) => {
        const newSelectedOptions = [...selectedOptions];
        newSelectedOptions[index] = selectedOption;
        setSelectedOptions(newSelectedOptions);
    };

    const serverOptions = [...servers.map(server => ({ value: server, label: server }))];

    const handleExpand = (selectedDataSource, selectedOptions) => {
        localStorage.setItem('DataSource', selectedDataSource);
        localStorage.setItem('Server', selectedOptions);
        // Navigate to the constructed URL
        onSelect('dashboard')
    };
    const updateAvgScore = (Score) => {
        setHealthScore(Score);
    };
    const updateClusterScore = (Score) => {
        setClusterScore(Score);
    }

    const handleRefresh = () => {
        // Get the current time - Reassigned in the case of custom
        let currentTime = new Date();

        // Calculate the new start time based on the selected duration
        let newStartTime = null;

        switch (lastDuration) {
            case '5 minutes':
                newStartTime = new Date(currentTime.getTime() - 5 * 60000);
                break;
            case '10 minutes':
                newStartTime = new Date(currentTime.getTime() - 10 * 60000);
                break;
            case '30 minutes':
                newStartTime = new Date(currentTime.getTime() - 30 * 60000);
                break;
            case '1 hour':
                newStartTime = new Date(currentTime.getTime() - 60 * 60000);
                break;
            case '2 hours':
                newStartTime = new Date(currentTime.getTime() - 2 * 60 * 60000);
                break;
            case '4 hours':
                newStartTime = new Date(currentTime.getTime() - 4 * 60 * 60000);
                break;
            case '6 hours':
                newStartTime = new Date(currentTime.getTime() - 6 * 60 * 60000);
                break;
            case '12 hours':
                newStartTime = new Date(currentTime.getTime() - 12 * 60 * 60000);
                break;
            case '1 day':
                newStartTime = new Date(currentTime.getTime() - 24 * 60 * 60000);
                break;
            case '3 days':
                newStartTime = new Date(currentTime.getTime() - 3 * 24 * 60 * 60000);
                break;
            case '1 week':
                newStartTime = new Date(currentTime.getTime() - 7 * 24 * 60 * 60000);
                break;
            case 'custom':
                // If custom is selected, don't update startTime or endTime
                newStartTime = startTime;
                currentTime = endTime;
                break;
            default:
                newStartTime = startTime;
                currentTime = endTime;
                break;
        }

        // Update the state with the new times
        setStartTime(newStartTime);
        setEndTime(currentTime);

        // Increment refreshKey to trigger re-fetching of data
        setRefreshKey(prevKey => prevKey + 1);
    };
    const handleclick = () => {
        if (dataSources.length === 0) {
            alert('Please contact your administrator. No data sources available.');
        }
    };

    return (
        <div className="dashboard" style={{ height: 'calc(92vh)', overflow: 'visible', marginTop: '30px' }}>

            <div className="dropdowns" style={{ width: sidebarOpen ? '89%' : '97.5%', display: 'flex' }}>
                <div className="dropdown-contents">

                    <div className="dropdown">
                        <CustomDropdown
                            label="Data Sources"
                            options={dataSources.map(source => ({ value: source, label: source }))}
                            value={selectedDataSource}
                            onChange={(event) => handleDataSourceChange(event)}
                        />
                    </div>

                    <div className="dropdown">
                        <CustomDropdown
                            label="Duration"
                            options={[
                                { value: '5 minutes', label: '5 minutes' },
                                { value: '10 minutes', label: '10 minutes' },
                                { value: '30 minutes', label: '30 minutes' },
                                { value: '1 hour', label: '1 hour' },
                                { value: '2 hours', label: '2 hours' },
                                { value: '4 hours', label: '4 hours' },
                                { value: '6 hours', label: '6 hours' },
                                { value: '12 hours', label: '12 hours' },
                                { value: '1 day', label: '1 day' },
                                { value: '3 days', label: '3 days' },
                                { value: '1 week', label: '1 week' },
                                { value: 'custom', label: 'custom' }
                            ]}
                            value={lastDuration}
                            onChange={(event) => handleLastDurationChange(event)}
                        />
                    </div>

                    {lastDuration === 'custom' && (
                        <>
                            <div className="dropdown">
                                <DatePicker
                                    selected={startTime}
                                    onChange={handleStartTimeChange}
                                    showTimeSelect
                                    dateFormat="yyyy-MM-dd HH:mm:ss"
                                    timeFormat="HH:mm:ss"
                                    timeCaption="Time"
                                    placeholderText="Select start time"
                                    className={`react-datepicker__input-container ${startTime ? 'selected' : ''}`}
                                    timeIntervals={5}
                                    maxDate={new Date()}
                                    filterTime={filterPassedTime}
                                    dayClassName={(date) => {
                                        if (date.getTime() > new Date().getTime()) {
                                            return 'react-datepicker__day--disabled';
                                        }
                                        return '';
                                    }}
                                    timeClassName={(time) => {
                                        if (new Date(time).getTime() > new Date().getTime()) {
                                            return 'react-datepicker__time-list-item--disabled';
                                        }
                                        return '';
                                    }}
                                />
                            </div>
                            <div className="dropdown">
                                <DatePicker
                                    selected={endTime}
                                    onChange={handleEndTimeChange}
                                    showTimeSelect
                                    dateFormat="yyyy-MM-dd HH:mm:ss"
                                    timeFormat="HH:mm:ss"
                                    timeCaption="Time"
                                    placeholderText="Select end time"
                                    className={`react-datepicker__input-container ${endTime ? 'selected' : ''}`}
                                    timeIntervals={5}
                                    maxDate={new Date()}
                                    filterTime={filterPassedTime}
                                    dayClassName={(date) => {
                                        if (date.getTime() > new Date().getTime()) {
                                            return 'react-datepicker__day--disabled';
                                        }
                                        return '';
                                    }}
                                    timeClassName={(time) => {
                                        if (new Date(time).getTime() > new Date().getTime()) {
                                            return 'react-datepicker__time-list-item--disabled';
                                        }
                                        return '';
                                    }}
                                />
                            </div>
                        </>
                    )}

                    <div>
                        <button onClick={handleRefresh} className="refresh-button">
                            <i className="bi bi-arrow-clockwise"></i>
                        </button>
                    </div>

                    {errorMessage && (
                            <div className="error-message-container">
                                <p>
                                    <i className="bi bi-exclamation-circle-fill" style={{ marginRight: '8px' }}></i>
                                    {errorMessage}
                                    <button onClick={dismissErrorMessage} style={{ background: 'none', border: 'none', color: '#B71C1C', cursor: 'pointer', marginLeft: '8px' }}>
                                        <i className="bi bi-x-circle-fill"></i>
                                    </button>
                                </p>
                            </div>
                        )}

                </div>
            </div>

            <div style={{ fontSize: '20px', color: '#023e8a', marginTop: '70px', marginBottom: '10px', display: 'flex', justifyContent: 'center', fontWeight: 'bold' }}>Anomaly Detection Intelligence</div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', marginBottom: '10px', maxHeight: '350px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <div className="metric-item" style={{ display: 'flex', flexDirection: 'column', padding: '25px', width: '200px', height: '50%', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
                        <div style={{ fontSize: '22px', fontWeight: 600, color: '#023e8a' }}>Servers</div>
                        <div style={{ fontSize: '45px', fontWeight: 500, color: '#cc7000' }}> {serverCount} </div>
                    </div>
                    <div className="metric-item" style={{ display: 'flex', flexDirection: 'column', width: '200px', padding: '25px', height: '50%', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
                        <div style={{ fontSize: '22px', fontWeight: 600, color: '#023e8a' }}>EnviScore</div>
                        <div style={{ fontSize: '45px', fontWeight: 500, color: clusterScore >= 8 ? 'green' : (clusterScore >= 6 ? '#cc7000' : '#CF1020') }}> {clusterScore} </div>
                    </div>
                </div>
                <div className="graph" style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }}>
                    <LandingGraph
                        key={refreshKey}
                        dataSource={selectedDataSource}
                        servers={servers}
                        startDate={startTime}
                        endDate={endTime}
                        metricName="cpu_usage"
                        labels="EnviVital"
                        updateClusterScore={updateAvgScore}
                    />
                </div>
            </div>

            <div className='graphs' style={{ marginTop: '10px', gap: '10px' }}>
                <div className='graph-total'>
                    <p>EnviScore</p>
                    <div className="graph">
                        <Graphnew
                            key={refreshKey}
                            dataSource={selectedDataSource}
                            label="EnviScore"
                            borderColor="rgba(0, 153, 128, 1)"
                            backgroundColor="rgba(0, 191, 160, 0.3)"
                            updateClusterScore={updateClusterScore}
                        />
                    </div>
                </div>

                <div className='graph-total'>
                    <p>EnviVital</p>
                    <GraphOptionsDropdown

                        selectedOption={selectedOptions[0]}
                        onChange={(option) => handleDropdownChange(0, option)}
                        options={serverOptions}
                        handleExpand={() => handleExpand(selectedDataSource, selectedOptions[0].value)}
                    />
                    <div className="graph">

                        <Graph
                            key={refreshKey}
                            dataSource={selectedDataSource}
                            server={selectedOptions[0] ? selectedOptions[0].value : servers[0]}
                            startDate={startTime}
                            endDate={endTime}
                            metricName="cluster.state"
                            label="EnviVital"
                            borderColor="rgba(0, 191, 160, 1)"
                            backgroundColor="rgba(0, 191, 160, 0.3)"
                            updateAvgScore={updateAvgScore}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Landing;