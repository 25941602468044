// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidechart {
    position:relative;
    width: 73%; /* Full width */
    min-height: 400px; /* Fixed height */
    background-color: white; /* Background color for the container */
    /* background-image: linear-gradient(to bottom, #fbfdff, #f0f4f8); */
    padding: 20px; /* Padding around the graph */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Box shadow for the container */
    transition: transform 0.3s ease, box-shadow 0.3s ease;    
}
`, "",{"version":3,"sources":["webpack://./src/components/Sidechart.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,UAAU,EAAE,eAAe;IAC3B,iBAAiB,EAAE,iBAAiB;IACpC,uBAAuB,EAAE,uCAAuC;IAChE,oEAAoE;IACpE,aAAa,EAAE,6BAA6B;IAC5C,kBAAkB,EAAE,oBAAoB;IACxC,wCAAwC,EAAE,iCAAiC;IAC3E,qDAAqD;AACzD","sourcesContent":[".sidechart {\n    position:relative;\n    width: 73%; /* Full width */\n    min-height: 400px; /* Fixed height */\n    background-color: white; /* Background color for the container */\n    /* background-image: linear-gradient(to bottom, #fbfdff, #f0f4f8); */\n    padding: 20px; /* Padding around the graph */\n    border-radius: 5px; /* Rounded corners */\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Box shadow for the container */\n    transition: transform 0.3s ease, box-shadow 0.3s ease;    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
