import React, { useState, useEffect } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'remixicon/fonts/remixicon.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './App.css';
import Header from './components/Header';
import Sidebar from './components/Sidebarnew';
import Dashboard from './components/Dashboard';
import Metrices from './components/Metrices';
import Landing from './components/Landing';
import Logs from './components/Logs';
import Login from './components/Login';
import Register from './components/Register';
import HomePage from './components/HomePage';
import { BrowserRouter as Router, Route, Navigate, Routes, useLocation } from 'react-router-dom';

// New Component to handle location and content
function AppContent({ toggleSidebar, handleLogout, handleSelect, selectedPage, sidebarOpen, setSidebarOpen, setSelectedPage, selectedMetric, setSelectedMetric }) {
    const location = useLocation(); // Get the current route

    useEffect(() => {
        // Collapse the sidebar when on the homepage
        if (location.pathname === '/') {
            setSidebarOpen(false);
        }
    }, [location.pathname, setSidebarOpen]);

    return (
        <>
            {location.pathname === '/dashboard' && (
                <>
                    <Header toggleSidebar={toggleSidebar} onLogout={handleLogout} />
                    <Sidebar
                        onSelect={handleSelect}
                        sidebarOpen={sidebarOpen}
                        toggleSidebar={toggleSidebar}
                        selectedPage={selectedPage}
                    />
                </>
            )}

<main
  id="main"
  className={`main ${location.pathname === '/dashboard' ? (sidebarOpen ? 'sidebar-open' : 'sidebar-collapsed') : ''}`}
  style={location.pathname === '/' ? { margin: '0' } : {}}
>
                <Routes>
                    <Route path="/" element={<HomePage handleLogout={handleLogout} />} />
                    <Route
                        path="/dashboard"
                        element={
                            selectedPage === 'analytics' ? (
                                <Landing sidebarOpen={sidebarOpen} onSelect={handleSelect} onLogout={handleLogout} />
                            ) : selectedPage === 'dashboard' ? (
                                <Dashboard sidebarOpen={sidebarOpen} onSelect={handleSelect} onLogout={handleLogout} />
                            ) : selectedPage === 'metrics' ? (
                                <Metrices selectedMetric={selectedMetric} sidebarOpen={sidebarOpen} onLogout={handleLogout} />
                            ) : selectedPage === 'logs' ? (
                                <Logs sidebarOpen={sidebarOpen} onLogout={handleLogout} />
                            ) : null
                        }
                    />
                    <Route path="/login" element={<Navigate to="/" />} />
                </Routes>
            </main>
        </>
    );
}

function App() {
    const [selectedPage, setSelectedPage] = useState('analytics'); // Default to 'dashboard'
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [selectedMetric, setSelectedMetric] = useState('CPU');
    const [isAuthenticated, setIsAuthenticated] = useState(false); // Authentication state

    useEffect(() => {
        // Check if the token is present to handle authentication
        const token = localStorage.getItem('authToken');
        if (token) {
            setIsAuthenticated(true);
        }
    }, []);

    useEffect(() => {
        // Update a CSS variable based on sidebar state
        document.documentElement.style.setProperty('--sidebar-width', sidebarOpen ? '225px' : '80px');
    }, [sidebarOpen]);

    const handleSelect = (page, metric) => {
        setSelectedPage(page);
        setSelectedMetric(metric);
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const handleLogin = (token) => {
        // Save token and update auth state
        localStorage.setItem('authToken', token);
        setIsAuthenticated(true);
    };

    const handleLogout = () => {
        // Remove token and update auth state
        localStorage.removeItem('authToken');
        setIsAuthenticated(false);
    };

    if (!isAuthenticated) {
        return (
            <Router>
                <div className="App">
                    <Routes>
                        <Route path="/login" element={<Login onLogin={handleLogin} />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="*" element={<Navigate to="/login" />} />
                    </Routes>
                </div>
            </Router>
        );
    }

    return (
        <Router>
            <AppContent
                toggleSidebar={toggleSidebar}
                handleLogout={handleLogout}
                handleSelect={handleSelect}
                selectedPage={selectedPage}
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
                setSelectedPage={setSelectedPage}
                selectedMetric={selectedMetric}
                setSelectedMetric={setSelectedMetric}
            />
        </Router>
    );
}

export default App;
