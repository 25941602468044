// This file is for System Logs. The related CSS file is Syslognew.css

import React, { useState, useEffect, useMemo } from 'react';
import './Syslognew.css';

const Syslogtest = ({ searchQuery, dataSource, host, start, end, logType }) => {
    const [logs, setLogs] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showSyslogDetails, setShowSyslogDetails] = useState(false);
    const [showAuthlogDetails, setShowAuthlogDetails] = useState(false);
    const [showApplicationlogDetails, setShowApplicationlogDetails] = useState(false);
    const [inputError, setInputError] = useState(null);


    useEffect(() => {
        if (!host || !start || !end) return;

        const fetchLogs = async () => {
            setLoading(true);
            setError(null);
            const url = `https://infia.enviseer.com/api/logs?cluster=${dataSource}&host=${encodeURIComponent(host)}&start=${encodeURIComponent(start)}&end=${encodeURIComponent(end)}`;
            console.log('Fetching logs from URL:', url);
             function getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            }
            
            const token = getCookie('token');
            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`, // Adding the Authorization header
                        'Accept': 'application/json'
                    }
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                console.log('Fetched logs:', data);
                setLogs(Array.isArray(data) ? data : []);
            } catch (error) {
                console.error('Error fetching logs:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchLogs();
    }, [host, start, end]);
    const escapeSpecialChars = (query) => {
        return query.replace(/([.+^${}()|[\]\\])/g, '\\$1');
    };

    const parseBooleanQuery = (query) => {
        try {
            const escapedQuery = escapeSpecialChars(query);
            const terms = escapedQuery.match(/("[^"]+"|[^,\s]+|,\s*)/g) || [];
            return terms.map(term => term.trim().replace(/"/g, ''));
        } catch (error) {
            setInputError('Malformed query input. Please check your query syntax.');
            return [];
        }
    };

    const highlightTerms = (content, query) => {
        const terms = parseBooleanQuery(query);
        let highlightedContent = content;
        terms.forEach(term => {
            if (!['AND', 'OR', 'NOT'].includes(term.toUpperCase()) && !term.includes(',')) {
                const regexPattern = term.replace(/\*/g, '.*').replace(/\?/g, '.');
                const regex = new RegExp(`(${regexPattern})`, 'gi');
                highlightedContent = highlightedContent.replace(regex, '<span class="highlight">$1</span>');
            }
        });
        return highlightedContent;
    };


    const applyBooleanLogic = (content, query) => {
        const terms = parseBooleanQuery(query);
        let result = true;
        let operator = 'AND';

        terms.forEach(term => {
            if (['AND', 'OR', 'NOT'].includes(term.toUpperCase())) {
                operator = term.toUpperCase();
            } else {
                const regexPattern = term.replace(/\*/g, '.*').replace(/\?/g, '.');
                const regex = new RegExp(regexPattern, 'i');
                const isMatch = regex.test(content);
                if (operator === 'AND') {
                    result = result && isMatch;
                } else if (operator === 'OR') {
                    result = result || isMatch;
                } else if (operator === 'NOT') {
                    result = result && !isMatch;
                }
            }
        });

        return result;
    };

    // const applyBooleanLogic = (content, query) => {
    //     const terms = parseBooleanQuery(query);
    //     let result = false;  // Initialize result as false to check for OR condition
    //     let operator = 'OR'; // Default operator to 'OR'

    //     terms.forEach(term => {
    //         if (['AND', 'OR', 'NOT'].includes(term.toUpperCase())) {
    //             operator = term.toUpperCase();
    //         } else {
    //             const regexPattern = term.replace(/\*/g, '.*').replace(/\?/g, '.');
    //             const regex = new RegExp(regexPattern, 'i');
    //             const isMatch = regex.test(content);

    //             if (operator === 'AND') {
    //                 result = result && isMatch;
    //             } else if (operator === 'OR') {
    //                 result = result || isMatch;
    //             } else if (operator === 'NOT') {
    //                 result = result && !isMatch;
    //             }
    //         }
    //     });

    //     return result;
    // };

    //csv
    const exportToCsv = (logs, filename) => {
        const csvContent = "data:text/csv;charset=utf-8,"
            + logs.map(log =>
                `${log.host},${new Date(log.timestamp).toLocaleString()},${log.level || 'null'},${log.content}`
            ).join("\n");

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    const filterLogs = (logs, logType, searchQuery) => {
        if (!logs || !Array.isArray(logs)) {
            return [];
        }

        return logs
            .filter(log => {
                if (logType === 'all') return true;
                if (logType === 'informational') {
                    return ['info', 'notice', 'debug', 'warning', null].includes(log.level);
                } else if (logType === 'error') {
                    return ['error', 'exception'].includes(log.level);
                } else if (logType === 'critical') {
                    return log.level === 'critical';
                }
                return true;
            })
            .filter(log => {
                if (!searchQuery) return true;
                const content = log.content.toLowerCase();
                return applyBooleanLogic(content, searchQuery);
            })
            .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    };

    const filteredLogs = useMemo(() => filterLogs(logs, logType, searchQuery), [logs, logType, searchQuery]);
    const syslogs = useMemo(() => filteredLogs.filter(log => log.filename.includes('syslog')||log.filename.includes('System')), [filteredLogs]);
    const authlogs = useMemo(() => filteredLogs.filter(log => log.filename.includes('auth.log')||log.filename.includes('Security')), [filteredLogs]);
    const applicationlogs = useMemo(() => filteredLogs.filter(log => log.filename.includes('Application')), [filteredLogs]);

    const toggleSyslogDetails = () => setShowSyslogDetails(!showSyslogDetails);
    const toggleAuthlogDetails = () => setShowAuthlogDetails(!showAuthlogDetails);
    const toggleApplicationlogDetails = () => setShowApplicationlogDetails(!showApplicationlogDetails)

    const renderLogs = (logs, showDetails, toggleDetails) => {
        const latestLog = logs[0];
        const remainingLogs = logs.slice(1);

        return (
            <div className="log-card">
                <h2>{logs === syslogs ? 'Syslogs' : logs=== authlogs? 'Authlogs': 'Applogs'}</h2>
                <p>Total Logs: <b>{logs.length}</b></p>
                {latestLog && (

                    // <div className="log-entry">
                    //     <p><strong>Host:</strong> {latestLog.host}</p>
                    //     <p><strong>Timestamp:</strong> {new Date(latestLog.timestamp).toLocaleString()}</p>
                    //     <p><strong>Level:</strong> {latestLog.level || 'null'}</p>
                    //     <p><strong>Content:</strong> <span dangerouslySetInnerHTML={{ __html: highlightTerms(latestLog.content, searchQuery) }} /></p>
                    // </div>

                    <div className="log-entry">
                        <div className="log-entry-header">
                            <div className="timestamp-level">
                                <p><strong>Timestamp:</strong> {new Date(latestLog.timestamp).toLocaleString()}</p>
                                <p><strong>Level:</strong> {latestLog.level || 'null'}</p>
                            </div>
                        </div>
                        <p><strong>Content:</strong> <span dangerouslySetInnerHTML={{ __html: highlightTerms(latestLog.content, searchQuery) }} /></p>
                    </div>
                )}

                <button onClick={toggleDetails}>{showDetails ? 'Show Less' : 'Show All'}</button>
                <button onClick={() => exportToCsv(logs, `Logs.csv`)}>
                    <i className="bi bi-download"></i> .csv
                </button>
                {showDetails && (

                    // <div className="log-details">
                    //     {remainingLogs.map((log, index) => (
                    //         <div key={index} className="log-entry">
                    //             <p><strong>Host:</strong> {log.host}</p>
                    //             <p><strong>Timestamp:</strong> {new Date(log.timestamp).toLocaleString()}</p>
                    //             <p><strong>Level:</strong> {log.level || 'null'}</p>
                    //             <p><strong>Content:</strong> <span dangerouslySetInnerHTML={{ __html: highlightTerms(log.content, searchQuery) }} /></p>
                    //         </div>
                    //     ))}
                    // </div>

                    <div className="log-details">
                        {remainingLogs.map((log, index) => (
                            <div key={index} className="log-entry">
                                <div className="log-entry-header">
                                    <div className="timestamp-level">
                                        <p><strong>Timestamp:</strong> {new Date(log.timestamp).toLocaleString()}</p>
                                        <p><strong>Level:</strong> {log.level || 'null'}</p>
                                    </div>
                                </div>
                                <p><strong>Content:</strong> <span dangerouslySetInnerHTML={{ __html: highlightTerms(log.content, searchQuery) }} /></p>
                            </div>
                        ))}
                    </div>

                )}
            </div>
        );
    };

    if (loading) {
        return <div>Loading logs...</div>;
    }

    if (error) {
        return <div>Error fetching logs: {error}</div>;
    }

    return (
        <div className="logs-container">
            {renderLogs(syslogs, showSyslogDetails, toggleSyslogDetails)}
            {renderLogs(authlogs, showAuthlogDetails, toggleAuthlogDetails)}
            {renderLogs(applicationlogs, showApplicationlogDetails, toggleApplicationlogDetails)}
        </div>
    );

};

export default Syslogtest;
