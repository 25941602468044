import React from 'react';
import styled from 'styled-components';
import { BiChevronDown, BiCalendar, BiSave, BiTrash } from 'react-icons/bi'; // Import necessary icons

// Styled component for the container
const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  background-color: #FFF; /*Adjusted for visibility*/
  border-radius: 20px; /*Rounded corners for modern look*/
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /*Subtle shadow*/
`;

// Styled component for the button
const DropdownButton = styled.button`
  background-color: #023e8a;
  background: #023e8a;
  color: #fff;
  font-weight: 400;
  padding: 8px 16px;
  font-size: 15px;
  border: none;
  border: 1px solid #0d2853;
  cursor: pointer;
  border-radius: 20px 20px 0px 0px;
  display: flex;
  justify-content: space-between; /* Ensures label and icon spacing */
  align-items: center;

  &:hover {
    background-color: #122e51; // Brighter blue on hover
    transform: translateY(-2px); // Slight lift effect
    box-shadow: 0 4px 8px rgba(0,0,0,0.2); // Enhanced shadow on hover for 3D effect
  }

`;

// Styled component for the dropdown content
const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  ${DropdownContainer}:hover & {
    display: block;
    opacity: 1;
  }
`;

// Styled component for the dropdown items
const DropdownItem = styled.div`
  color: black;
  padding: 8px 12px;
  font-size: 14px;
  text-decoration: none;
  display: flex;
  align-items: center; /* Vertical alignment for icons and text */
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #e0e0e0; // Lighter grey for hover
    font-weight: bold;
  }
`;

const CustomDropdown = ({ label, options, onChange, value }) => {
  const selectedOption = options.find(option => option.value === value);


  const displayLabel = selectedOption ? `${label} : ${selectedOption.label}` : label;  // Using Unicode for dropdown symbol
  return (
    <DropdownContainer>
      <DropdownButton>
        {displayLabel}
        {selectedOption ? null : <BiChevronDown />}
      </DropdownButton>
      <DropdownContent>
        {options.map((option, index) => (
          <DropdownItem
            key={index}
            onClick={() => onChange({ target: { value: option.value } })}
          >

            {option.label}
          </DropdownItem>
        ))}
      </DropdownContent>
    </DropdownContainer>
  );
};

export default CustomDropdown;
