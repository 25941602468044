import React, { useState, useEffect } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'remixicon/fonts/remixicon.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './App.css';
import Header from './components/Header';
import Sidebar from './components/Sidebarnew';
import Dashboard from './components/Dashboard';
import Metrices from './components/Metrices';
import Landing from './components/Landing';
import Logs from './components/Logs';
import Login from './components/Login';
import Register from './components/Register';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

function App() {
    const [selectedPage, setSelectedPage] = useState('analytics'); // Default to 'dashboard'
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [selectedMetric, setSelectedMetric] = useState('CPU');
    const [isAuthenticated, setIsAuthenticated] = useState(false); // Authentication state

    useEffect(() => {
        // Check if the token is present to handle authentication
        const token = localStorage.getItem('authToken');
        console.log("eeeeeeeeee",token);
        if (token) {
            setIsAuthenticated(true);
        }
    }, []);

    useEffect(() => {
        // Update a CSS variable based on sidebar state
        document.documentElement.style.setProperty('--sidebar-width', sidebarOpen ? '225px' : '80px');
    }, [sidebarOpen]);

    const handleSelect = (page, metric) => {
        setSelectedPage(page);
        setSelectedMetric(metric);
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const handleLogin = (token) => {
        // Save token and update auth state
        localStorage.setItem('authToken', token);
        setIsAuthenticated(true);
    };

    const handleLogout = () => {
        // Remove token and update auth state
        localStorage.removeItem('authToken');
        setIsAuthenticated(false);
    };

    if (!isAuthenticated) {
        // Show login/register routes if not authenticated
        return (
            <Router>
                <div className="App">
                    <Switch>
                        <Route path="/login" render={() => <Login onLogin={handleLogin} />} />
                        <Route path="/register" component={Register} />
                        <Redirect to="/login" /> {/* Redirect all other routes to login */}
                    </Switch>
                </div>
            </Router>
        );
    }

    return (
        <Router>
            <>
                <Header toggleSidebar={toggleSidebar} onLogout={handleLogout} />
                <Sidebar
                    onSelect={handleSelect}
                    sidebarOpen={sidebarOpen}
                    toggleSidebar={toggleSidebar}
                    selectedPage={selectedPage}
                />
                <main id="main" className={`main ${sidebarOpen ? 'sidebar-open' : 'sidebar-collapsed'}`}>
                    <Switch>
                        <Route
                            exact
                            path="/"
                            render={() =>
                                selectedPage === 'analytics' ? (
                                    <Landing sidebarOpen={sidebarOpen} onSelect={handleSelect} onLogout={handleLogout} />
                                ) : selectedPage === 'dashboard' ? (
                                    <Dashboard sidebarOpen={sidebarOpen} onSelect={handleSelect} onLogout={handleLogout}/>
                                ) : selectedPage === 'metrics' ? (
                                    <Metrices selectedMetric={selectedMetric} sidebarOpen={sidebarOpen} onLogout={handleLogout}/>
                                ) : selectedPage === 'logs' ? (
                                    <Logs sidebarOpen={sidebarOpen} onLogout={handleLogout}/>
                                ) : null
                            }
                        />
                        <Route path="/login" render={() => <Redirect to="/" />} />
                    </Switch>
                </main>
            </>
        </Router>
    );
}

export default App;
