import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom'; // Import withRouter for navigation
import './Register.css';

const Register = ({ history }) => { // Access history from props
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [otp, setOtp] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(30); // Countdown state
  const [isLoading, setIsLoading] = useState(false); // New loading state

  useEffect(() => {
    let timer;
    if (isResendDisabled && countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsResendDisabled(false);
      setCountdown(30); // Reset the countdown
    }

    return () => clearTimeout(timer);
  }, [isResendDisabled, countdown]);

  const handleSendOtp = async () => {
    if (firstName && lastName && email && displayName && customerId) {
      setErrorMessage('');
      setIsLoading(true); // Start loading
      try {
        // Create the URL with query parameters
        const url = `https://infia.enviseer.com/api/api/user/register?email=${encodeURIComponent(email)}&firstName=${encodeURIComponent(firstName)}&lastName=${encodeURIComponent(lastName)}&displayName=${encodeURIComponent(displayName)}&customerId=${encodeURIComponent(customerId)}`;
  
        // API call with query parameters in the URL
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-Application-Name': 'envi-onboarding',
          },
          // No need for a body since the parameters are passed in the URL
        });

        const data = await response.text();

        if (response.ok) {
          if (data === "User already exists. Please log in.") {
            setErrorMessage('Email is already registered. Please log in.');
          } else {
            setIsOtpSent(true);
            setErrorMessage('');
            setIsResendDisabled(true); // Disable resend button
            console.log("OTP sent to:", email);
          }
        } else {
          setErrorMessage('Failed to send OTP. Please try again.');
        }
      } catch (error) {
        setErrorMessage('Failed to send OTP. Please try again.');
        console.error('Error details:', error); // Detailed logging
      } finally {
        setIsLoading(false); // Stop loading
      }
    } else {
      setErrorMessage('Please fill out all required fields.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading
    try {
      // Create the URL with query parameters
      const url = `https://infia.enviseer.com/api/api/user/verify?email=${encodeURIComponent(email)}&firstName=${encodeURIComponent(firstName)}&lastName=${encodeURIComponent(lastName)}&displayName=${encodeURIComponent(displayName)}&customerId=${encodeURIComponent(customerId)}&otp=${encodeURIComponent(otp)}`;
    
      // API call with query parameters in the URL
      const response = await fetch(url, {
        method: 'POST',  // Still using POST method but no body since parameters are in the URL
        headers: {
          'Content-Type': 'application/json',
          'X-Application-Name': 'envi-onboarding',
        },
        // No need for a body since all the parameters are sent in the URL
      });
      const data = await response.text();

      if (response.ok && data === "User verified successfully.") {
        setErrorMessage('');
        console.log("OTP validated, you can now proceed.");
        setShowSuccessModal(true); // Show the success modal
      } else {
        setErrorMessage('Invalid OTP. Please try again.');
      }
    } catch (error) {
      setErrorMessage('Failed to validate OTP. Please try again.');
      console.error('Error details:', error); // Detailed logging
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  // Function to navigate to login page
  const handleNavigateToLogin = () => {
    history.push('/login'); // Use history.push to navigate to the login page
  };

  // Function to close the modal and navigate to the login page after success
  const handleCloseModal = () => {
    setShowSuccessModal(false);
    history.push('/login'); // Navigate to login after successful registration
  };

  return (
    <div className="register-container">
      <div className="register-box">
        <div className="tabs">
          <button className="tab" onClick={handleNavigateToLogin}>LOGIN</button> 
          <button className="tab active">REGISTER</button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              id="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="input-group">  
            <label htmlFor="displayName">Display Name</label>
            <input
              type="text"
              id="displayName"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="customerId">Customer ID</label>
            <input
              type="number"
              id="customerId"
              value={customerId}
              onChange={(e) => setCustomerId(e.target.value)}
              required
            />
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>} 
          {isOtpSent && (
            <div className="input-group">
              <label htmlFor="otp">Enter your OTP</label>
              <input
                type="text"
                id="otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
              />
            </div>
          )}
          <div className="actions">
            {isOtpSent ? (
              <>
                <button type="button" onClick={handleSendOtp} className="resend-button" disabled={isResendDisabled}>
                  {isResendDisabled ? `Resend OTP in ${countdown}s` : 'Resend OTP'}
                </button>
                <button type="submit" className="submit-button" disabled={isLoading}>
                  {isLoading ? 'Submitting...' : 'Submit'}
                </button>
              </>
            ) : (
              <button type="button" onClick={handleSendOtp} className="submit-button" disabled={isLoading}>
                {isLoading ? 'Sending OTP...' : 'Request OTP'}
              </button>
            )}
          </div>
        </form>
      </div>

      {/* Success Modal */}
      {showSuccessModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Success!</h2>
            <p>You have been successfully registered.</p>
            <button onClick={handleCloseModal}>OK</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(Register); // Wrap the component with withRouter to get access to history
