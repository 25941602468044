import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const HealthScore = ({ score }) => {
    const getColor = (value) => {
        if (value >= 8) {
            return 'green';
        } else if (value >= 6) {
            return '#cc7000'; // Orange color
        } else {
            return '#CF1020'; // Red color
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <div style={{ width: '100px', height: '100px', fontWeight: 'bold'}}>
                <CircularProgressbar
                    value={score * 10} // To map 0-10 with 0-100, ex. 7 becomes 70
                    text={`${score}`}
                    styles={buildStyles({
                        pathColor: getColor(score),
                        textColor: getColor(score),
                        trailColor: '#fff',
                        rotation: 0.75, // Rotating for semi-circular appearance
                        strokeLinecap: 'butt', // Smooth edges for the progress bar
                        textSize: '20px', // Keeping it consistent with the rest fo the fonts
                        fontWeight: 'bold', // Ensure consistent font weight
                        pathTransitionDuration: 0.8, // Smooth transition duration
                        pathTransition: 'ease-in-out', // Smooth transition
                        trailWidth: 10,
                        pathWidth: 15,
                        backgroundColor: '#fff', // Background color of the circle
                        shadow: `0 0 10px ${getColor(score)}`, // Add shadow for a more polished look
                    })}
                    strokeWidth={13} // Thickness of the progress bar
                    circleRatio={0.5} // Arc-length of the progress bar
                />
            </div>
        </div>
    );
};

export default HealthScore;
