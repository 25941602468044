import React from 'react';
import './Dashboard.css';
//import Dropdowngraphs from './Dropdowngraphs';
//import Dropdownnew  from  './Dropdownnew';
import Dropdownlatest from './Dropdownlatest';
function Dashboard({sidebarOpen, onSelect}) {
    return (

	    <Dropdownlatest sidebarOpen={sidebarOpen} navigate={onSelect}/>
    );
}

export default Dashboard;
