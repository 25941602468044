// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.metrices {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
}

.metric {
    position: relative;
    flex-wrap: wrap;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(50% - 20px); /* Each graph takes the full width */
    min-width: 200px; /* Minimum width for each graph */
    min-height: 300px;
    background-color: white; /* Background color for graph container */
    padding: 20px; /* Padding around the graph */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Box shadow for graph container */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.graph-options-dropdown {
	    position: absolute;
	    top: 10px;
	     right: 10px;
	     width: 200px;
 }


`, "",{"version":3,"sources":["webpack://./src/components/Metrices.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,YAA0B;IAA1B,cAA0B;IAA1B,4BAA0B,EAAE,oCAAoC;IAChE,gBAAgB,EAAE,iCAAiC;IACnD,iBAAiB;IACjB,uBAAuB,EAAE,yCAAyC;IAClE,aAAa,EAAE,6BAA6B;IAC5C,kBAAkB,EAAE,oBAAoB;IACxC,wCAAwC,EAAE,mCAAmC;IAC7E,qDAAqD;AACzD;;AAEA;KACK,kBAAkB;KAClB,SAAS;MACR,WAAW;MACX,YAAY;CACjB","sourcesContent":["\n.metrices {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    gap: 20px;\n    margin-top: 20px;\n}\n\n.metric {\n    position: relative;\n    flex-wrap: wrap;\n    flex: 1 1 calc(50% - 20px); /* Each graph takes the full width */\n    min-width: 200px; /* Minimum width for each graph */\n    min-height: 300px;\n    background-color: white; /* Background color for graph container */\n    padding: 20px; /* Padding around the graph */\n    border-radius: 5px; /* Rounded corners */\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Box shadow for graph container */\n    transition: transform 0.3s ease, box-shadow 0.3s ease;\n}\n\n.graph-options-dropdown {\n\t    position: absolute;\n\t    top: 10px;\n\t     right: 10px;\n\t     width: 200px;\n }\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
