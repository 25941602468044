// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard {
    padding: 20px;
    margin-top: 50px;  /* Adjust this if needed */
    height: calc(100vh);  /* Adjust this if needed */
    width: calc(100%);  /* Adjust this if needed */
    background-color: #aab7cf transparent;
}

`, "",{"version":3,"sources":["webpack://./src/components/Dashboard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB,GAAG,0BAA0B;IAC7C,mBAAmB,GAAG,0BAA0B;IAChD,iBAAiB,GAAG,0BAA0B;IAC9C,qCAAqC;AACzC","sourcesContent":[".dashboard {\n    padding: 20px;\n    margin-top: 50px;  /* Adjust this if needed */\n    height: calc(100vh);  /* Adjust this if needed */\n    width: calc(100%);  /* Adjust this if needed */\n    background-color: #aab7cf transparent;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
