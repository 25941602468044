import React, { useState, useEffect, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { formatDateTime, convertToIST, getCondensedData } from './utils'; // Assuming you have utility functions in a utils.js file
import { Chart, registerables } from 'chart.js';
// Register Chart.js components
Chart.register(...registerables);
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const verticalLinePlugin = {
    id: 'verticalLinePlugin',
    afterDraw: (chart) => {
        if (chart.tooltip._active && chart.tooltip._active.length) {
            const ctx = chart.ctx;
            ctx.save();

            const activePoint = chart.tooltip._active[0];
            const x = activePoint.element.x;
            const yScale = chart.scales.y;

            // Check if yScale is defined and has a top property
            if (!yScale || typeof yScale.top === 'undefined') {
                console.error('yScale or yScale.top is undefined');
                return;
            }
            const topY = chart.scales.y.top;
            const bottomY = chart.scales.y.bottom;
            const label = chart.data.labels[activePoint.index];

            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 2;
            ctx.strokeStyle = 'rgba(255, 255, 255, 0.2)'; // Adjusted for a subtle line
            ctx.stroke();
            ctx.restore();

            const labelX = x;
            const labelY = bottomY + 20;
            ctx.fillStyle = '#000';
            ctx.fillRect(labelX - 20, labelY - 10, 40, 20); // Adjust width and height accordingly
            ctx.fillStyle = '#fff';
            ctx.font = '12px Arial';
            ctx.textAlign = 'center';
            ctx.fillText(label.substring(0, 5), x, bottomY + 20);
            ctx.fillText(label.substring(6, label.length), x, bottomY + 35);
            ctx.restore();
        }
    }
};

ChartJS.register(verticalLinePlugin);

const generateChartData = (timestamps, data, label, borderColor, backgroundColor) => {
    const ctx = document.createElement('canvas').getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, borderColor);
    gradient.addColorStop(0, backgroundColor);
    gradient.addColorStop(0.7, 'rgba(0,210,255,0)'); // Transparent end

    return {
        labels: timestamps.map(convertToIST),
        datasets: [
            {
                label: label,
                data: data,
                fill: true,
                borderColor: borderColor,
                backgroundColor: gradient,
                tension: 0.3,
                pointBorderWidth: 0.6,
                pointRadius: 0,
                borderWidth: 2,
                pointBackgroundColor: borderColor,
                pointStyle: 'circle',
                pointHoverRadius: 7,
                pointHoverBorderWidth: 3,
                pointHoverBorderColor: 'rgba(0, 0, 0, 0.2)',
                pointShadowOffsetX: 1,
                pointShadowOffsetY: 1,
                pointShadowBlur: 2,
                pointShadowColor: 'rgba(0, 0, 0, 0.5)',
            },
        ],
    };
};

const chartOptions = (metricName) => {
    const percentageMetrics = ['cpu_usage', 'memory_usage', 'disk_usage'];
    return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    autoSkip: true,
                    maxRotation: 45,
                    minRotation: 0,
                    maxTicksLimit: 7,
                    color: '#888', // Light grey for the ticks
                    callback: function (value) {
                        let label = this.getLabelForValue(value);
                        if (label.length > 5) {
                            label = label.substring(5);
                        }
                        return label;
                    }
                },
                title: {
                    display: true,
                },
            },
            y: {
                grid: {
                    color: 'rgba(200, 200, 200, 0.2)', // Light y-axis grid lines
                },
                ticks: {
                    color: '#888',
                    fontSize: 12,
                },
                beginAtZero: true,
                title: {
                    display: false,
                    text: 'Value',
                },
            },
        },
        plugins: {
            legend: {
                display: false,
                position: 'top',
                labels: {
                    boxWidth: 0,
                }
            },
            tooltip: {
                enabled: true,
                backgroundColor: 'black',
                titleFontColor: '#fff',                   
                bodyFontColor: '#fff',                     
                borderColor: '#666',                       
                borderWidth: 1,
                cornerRadius: 4,
                xPadding: 10,
                yPadding: 10,
                displayColors: false,
                mode: 'index',
                intersect: false,
                callbacks: {
                    label: (context) => {
                        let label = context.dataset.label || '';
                        // Formatting based on metricType
                        if (percentageMetrics.includes(metricName)) {
                            label += ': ' + context.raw + '%';
                        } else {
                            label += ': ' + context.raw;
                        }

                        return label;
                    },
                },
            },
            verticalLinePlugin: true,
        },
        hover: {
            mode: 'index',
            intersect: false,
        },

    };
};
const fetchMetricData = async (dataSource, metricName, label, server, start, end) => {
    const formattedStartTime = formatDateTime(start);

    console.log(metricName);
    const formattedEndTime = formatDateTime(end);
    const url = label === 'EnviVital' ? `https://infia.enviseer.com/api/clusters/${dataSource}/healthscores?hostname=${server}&start=${formattedStartTime}&end=${formattedEndTime}&metric=${metricName}&mode=moderate` : `https://infia.enviseer.com/api/metrics?&cluster=${dataSource}&hostname=${server}&start=${formattedStartTime}&end=${formattedEndTime}&metric=${metricName}`;
    console.log(url);
     function getCookie(name) {
                const value = `; ${document.cookie}`;
                const parts = value.split(`; ${name}=`);
                if (parts.length === 2) return parts.pop().split(';').shift();
            }
            
            const token = getCookie('token');
     const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`, // Adding the Authorization header
                'Accept': 'application/json'
            }
        });
    if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log("JJJJJJJJJJJJJJJ", metricName, data);
    if (Array.isArray(data)) {
        const timestamps = data.map(entry => entry.timestamp);
        const values = data.map(entry => entry.value);
        return { timestamps, values };
    } else {
        return { timestamps: [], values: [] };
    }
};

const Graph = ({ refreshKey, dataSource, server, startDate, endDate, metricName, label, borderColor, backgroundColor, updateAvgScore }) => {
    const [timestamps, setTimestamps] = useState([]);
    const [metricData, setMetricData] = useState([]);
    const chartRef = useRef(null);

    const calculateAverage = (values) => {

        if (values.length === 0) return 0;
        const sum = values.reduce((acc, curr) => acc + curr, 0);
        return sum / values.length;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchMetricData(dataSource, metricName, label, server, startDate, endDate);
                setTimestamps(data.timestamps);
                const formattedValues = data.values.map(entry => {
                    // Check if entry.value is valid and a number
                    const num = parseFloat(entry);
                    return isNaN(num) ? '0.00' : num.toFixed(2);
                });
                setMetricData(formattedValues);
                const averageValue = calculateAverage(data.values);
                console.log("LLLLLLLLLLLLLLLLL",metricName, timestamps,metricData);
                if(metricName==='cluster.state'){
                     updateAvgScore(averageValue.toFixed(2));
                }
               
            } catch (error) {
                console.error(`Error fetching ${metricName} data:`, error);
                setTimestamps([]);
                setMetricData([]);
            }
        };

        fetchData();
    }, [dataSource, server, startDate, endDate, metricName]);



    const { condensedTimestamps, condensedMetricData } = getCondensedData(timestamps, metricData);
    const chartData = generateChartData(condensedTimestamps, condensedMetricData, label, borderColor, backgroundColor);
    console.log('chartData', chartData);


    return <Line key={refreshKey} data={chartData} options={chartOptions(metricName)} ref={chartRef} />;

};

export default Graph;

